import { Component } from '@angular/core';
// import { CarouselModule, WavesModule, ButtonsModule } from '@ng-bootstrap/ng-bootstrap';
import {NgbCarouselConfig} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [NgbCarouselConfig]// , NgbCarouselModule, NgbButtonsModule]
})

export class AppComponent {
  title = 'Shattered Glass Audio';
  constructor(config: NgbCarouselConfig){
    config.interval = 3000;
    config.pauseOnHover = true;
  }
}
