import { Component, OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

import { ProductService } from '../product.service';
import { SupportRequest } from '../product';

@Component({
  selector: 'app-support-request',
  templateUrl: './support-request.component.html',
  styleUrls: ['./support-request.component.css']
})
export class SupportRequestComponent implements OnInit {
  public name: string;
  public email: string;
  public daw: string;
  public daw_ver: string;
  public oss: string[] = ['macOS', 'Windows (64-bit)', 'Windows (32-bit)', 'iOS'];
  public os: string = this.oss[0];
  public os_ver: string;
  public products: string[] = ['Kompresor', 'Phoenix 2', 'Phoenix 2 Demo', 'Inferno', 'Inferno Demo', 'Code Red', 'Mini Cracked Glass', 'Mini Cracked Glass Demo', 'Code Red Free', 'SGA1566', 'Ace'];
  public problemTypes: string[] = ['Licensing issue.', 'Problem with the plugin.', 'Question or issue with my order.'];
  public problem_type: string = this.problemTypes[1];
  public product: string = this.products[0];
  public subject: string;
  public description: string;
  public hasError: boolean = false;
  public error: string;
  public successMessage: string;
  public isSubmitted: boolean = false;
  public isCompleted: boolean = false;

  constructor(
    private productService: ProductService,
    private logger: NGXLogger
  ) { }

  ngOnInit() {
  }

  submit(captchaResponse: string) {
    const supportRequest: SupportRequest = new SupportRequest(
      captchaResponse,
      this.name.trim(),
      this.email.trim(),
      this.daw,
      this.daw_ver,
      this.os,
      this.os_ver,
      this.product.trim(),
      this.subject,
      this.description,
      this.problem_type
    );
    this.isSubmitted = true;
    this.productService.submitSupportRequest(supportRequest).subscribe(
      (val) => {
        this.logger.debug('Service Request response: ', val);
        this.hasError = val.hasError;
        if (this.hasError)
          this.error = val.error;
        else
          this.successMessage = val.message;
        this.isCompleted = true;
      },
      error => {
        this.logger.error('Error while submitting support request. ', error);
        this.hasError = true;
        this.error = 'Server error has occurred while submitting your support request. Please, try again later. If the problem persists contact us at support at shatteredglassaudio dot com.';
        this.isCompleted = true;
      }
    );
  }
}
