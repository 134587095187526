<div *ngIf="product$ | async as product"> 
  <div class="banner">
    <img class="image" src="/assets/images/{{product.bannerImage}}" />
    <div class="banner-title" [style.color]="product.bannerTextColor == undefined ? 'rgb(169, 169, 169)' : product.bannerTextColor">
      {{product.name}} 
    </div>
  </div>

  <div class="sticky">
    <ul class="detailnav">
      <!-- style="background-color:#009bff; color: #ffffff;"> -->
      <li *ngFor="let sectionName of product.sectionNames">
        <a class="sub" [routerLink]="'/product/'+product.id" [fragment]="sectionName[sectionName.length-1]" [routerLinkActiveOptions]="{ queryParams: 'preserve', fragment: 'enabled' }">{{sectionName[0]}}</a>
        <!-- this._router.navigate( ['/product/', product.id ], {fragment: sectionName}); -->
      </li>
      <li *ngIf="!product.isFree">
        <!-- class="detailnav" style="position: relative;"> -->
        <div style="position: relative">
          <!-- <span *ngIf="product.price" style="text-align: right; margin-left: 10px; margin-right: 10px">
            ${{product.price}} </span> -->
          <a *ngIf="product.appleAppStoreLink" class="sub" [href]="product.appleAppStoreLink">
            <img src="/assets/images/Download_on_the_App_Store_Badge.svg" style="margin-right: 10px"/> 
          </a>
          <a *ngIf="product.isWinMac === undefined || product.isWinMac === null || product.isWinMac" class="sub" [routerLink]="'/store/'+product.id">
            <!-- <button class="btn btn-primary sga-btn">BUY HERE</button> -->
            <button class="btn buy-button">BUY HERE</button>
          </a>
        </div>
      </li>
      <li *ngIf="product.isFree && product.donateUrl">
        <div style="position: relative">
            <a class="sub" href="{{product.donateUrl}}">
              <button class="btn buy-button gold-btn">DONATE</button>
            </a>
          </div>
      </li>
    </ul>
  </div>
  <div [class]="'detail-row ' + (product.mainTheme ? product.mainTheme : '')">
    <div *ngFor="let item of product.heading" class="heading">
      <span>{{item}}</span>
    </div>
  </div>
  <div *ngFor="let section of product.sections; let i = index;">
    <div [class]="'detail-row ' + (((i%2) == 1 )? (product.altTheme ? product.altTheme : 'default-alternate'): (product.mainTheme ? product.mainTheme : ''))">
      <div id="{{section.id}}" [class]="'detail-container ' + (section.textImage ? 'centered ' : '')"  [style.overflow]="section.featuresImages ? 'visible' : 'hidden'">
        <ng-container *ngIf="section.textImage">
          <div *ngIf="section.textImage?.text" [class]="(section.textImage.textPosition == 'left' || section.textImage.textPosition == 'right') ? 'detail-2col '+section.textImage.textPosition : 'detail-1col'">
            <!-- [style.float]="(section.textImage.textPosition == 'left' || section.textImage.textPosition == 'right') ? section.textImage.textPosition :''"> -->
            <div *ngIf="section.textImage.text.title" [class]="'section-title' + ((section.textImage.textPosition == 'left' || section.textImage.textPosition == 'right') ? '' : ' center')">
              <span> {{section.textImage.text.title | uppercase}}</span>
            </div>
            <!-- <span>Blaaaaah {{section.textImage.textPosition}}</span> -->
            <div *ngIf="section.textImage?.text?.text?.length > 0" class="section-text">
              <span *ngFor="let line of section.textImage.text.text; last as isLast">
                {{line}}<br *ngIf="!isLast">
              </span>
            </div>
            <div *ngIf="section.textImage?.text?.textList?.length > 0" class="section-text">
              <ul>
                <li *ngFor="let line of section.textImage.text.textList">{{line}}</li>
              </ul>
            </div>
            <div *ngIf="section.textImage?.text?.paragraphs?.length > 0" class="section-text">
              <p *ngFor="let paragraph of section.textImage.text.paragraphs">
                <span *ngFor="let line of paragraph; last as isLast" [innerHtml]="line | safe: 'html'">
                  <!-- {{line}}<br *ngIf="!isLast"> -->
                  <br *ngIf="!isLast">
                </span>
              </p>
            </div>
          </div>
          <div *ngIf="section.textImage?.image" [class]="(section.textImage.textPosition == 'left' || section.textImage.textPosition == 'right') ? 'detail-2col' : 'detail-1col'"
            [style.float]="(section.textImage.textPosition == 'left' || section.textImage.textPosition == 'right') ? (section.textImage.textPosition=='left' ? 'right' : 'left') :''">
            <img src="{{section.textImage.image}}" class="section-image">
          </div>
        </ng-container>

        <ng-container *ngFor="let item of section.featuresImages">
           <!-- *ngIf="section.featuresImage"> -->
          <div *ngIf="item.title" class="section-title center">
            <span>{{item.title}}</span>
          </div>
          <div *ngIf="!item.title && !item.text" class="features-pad"></div>
          <div [class]="item.divClass?item.divClass:'features-container'"
            [style.max-width]="item.maxWidth?item.maxWidth:''">
  
            <img src="{{item.image}}" [class]="item.imageClass?item.imageClass:'features-image'" />
            <ng-container *ngFor="let feature of item.features">
              <ng-template #tipContent><span [innerHTML]="feature.text"></span></ng-template>
              <div class="hotspot" [placement]="feature.placement ? feature.placement : 'top'" [style.top]="feature.y"
                [style.left]="feature.x" [ngbTooltip]="tipContent" triggers="click hover" [tooltipClass]="(feature.class?feature.class:'tooltip-default')"></div>
            </ng-container>
          </div>
          <div class="features-pad"></div>
        </ng-container>

        <ng-container *ngIf="section.downloads">
          <div class="detail-1col" style="padding: 10px 0px">
            <div class="section-title center">
              <span>DOWNLOAD</span>
            </div>
            <div class="section-text">
              <span><strong>Current Version:</strong> {{section.downloads.version}}</span>
            </div>
            <!-- <div style="display: table!important; position: relative; width: 100%; height: 100px; overflow: hidden;"> -->
            
            <div style="display: block; position: relative; float: left;" [style.max-width]="section.downloads.downloadNameMaxWidth">
                <div  *ngFor="let dnld of section.downloads.downloadDetails" style="display: block; position: relative; padding: 7px 0px 6px;">
                  <div class="section-text" style="display: inline-block; width: auto; vertical-align: middle; padding-right: 5px;">
                    <span>{{dnld.name}}:</span>
                  </div>
                </div>
            </div>
            <div style="display: inline-block; position: relative;">
              <div *ngFor="let dnld of section.downloads.downloadDetails" style="display: block; position: relative; padding: 5px 0px;">
                <div *ngFor="let installer of dnld.installers" style="display:  inline-block; position: relative; width: auto;">
                  <!-- {{installer.length == 2 || installer[2] == 'href'}} -->
                  <a *ngIf="installer.length == 2 || installer[2] == 'href'" href="{{installer[1]}}">
                    <button class="btn download-button">{{installer[0]}}</button>
                  </a>
                  <a *ngIf="installer.length == 3 && installer[2] == 'routerLink'" [routerLink]="[installer[1]]" routerLinkActive="router-link-active">
                    <button class="btn download-button">{{installer[0]}}</button>
                  </a>
                  <span class="section-text" *ngIf="installer.length == 3 && installer[2] == 'innerHTML'" style="padding: 2px 0px 0px;"[innerHTML]="installer[0]"></span>
                </div>
              </div>
            </div>
            <div *ngIf="section.downloads.userManual" class="section-text">
              <a href="{{section.downloads.userManual}}">{{product.name}} User Manual</a>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="section.systemRequirements">
          <div class="detail-1col">
            <div class="section-title center">
              <span>SYSTEM REQUIREMENTS</span>
            </div>
            <div class="section-text">
              <p>Mac: {{section.systemRequirements.macOS}}</p>
              <p>Windows: {{section.systemRequirements.windows}}</p>
            </div>
            <div class="section-text">
              <b>Supported Formats:</b><br>
              Mac {{section.systemRequirements.macInterfaces}}<br>
              Windows {{section.systemRequirements.windowsInterfaces}}
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

</div>
  <!-- <div *ngFor="let section of product.sections">
    <div id="{{section.id}}">
      <ng-container *ngIf="section.textImage?.text?.text?.length > 0">
        <span *ngFor="let line of section.textImage.text.text; last as isLast">
          {{line}}<br *ngIf="!isLast">
        </span>
      </ng-container>
      <ng-container *ngIf="section.textImage?.text?.textList?.length > 0">
        <ul>
          <li *ngFor="let line of section.textImage.text.textList">{{line}}</li>
        </ul>
      </ng-container>
      <ng-container *ngIf="section.textImage?.text?.paragraphs?.length > 0">
        <p *ngFor="let paragraph of section.textImage.text.paragraphs">
          <span *ngFor="let line of paragraph; last as isLast">
            {{line}}<br *ngIf="!isLast">
          </span>
        </p>
      </ng-container>
      <div *ngIf="section.featuresImage" [class]="[section.featuresImage.divClass?section.featuresImage.divClass:'features-container-landscape']"
        [style.max-width]="[section.featuresImage.maxWidth?section.featuresImage.maxWidth:'']">
        <img src="{{section.featuresImage.image}}" [class]="[section.featuresImage.imageClass?section.featuresImage.imageClass:'features-image-landscape']" />
        <ng-container *ngFor="let feature of section.featuresImage.features">
          <ng-template #tipContent><span [innerHTML]="feature.text"></span></ng-template>
          <div class="hotspot" [style.top]="[feature.y]" [style.left]="[feature.x]" [ngbTooltip]="tipContent"></div>

        </ng-container>
      </div>
    </div>
  </div> -->
