import { Component, OnInit } from '@angular/core';
import { NGXLogger, INGXLoggerConfig } from 'ngx-logger';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {
  // public flags: Map<string, boolean>;
  public flags: { [name: string]: boolean; } = {
    'install': false,
    'purchase': false,
    'license': false,
    'demo': false,
    'analog': false,
  };

  constructor(private logger: NGXLogger) { }

  ngOnInit() {
  }

  change(name: string) {
    for(const [key, value] of Object.entries(this.flags)){
      this.flags[key] = key == name ? !this.flags[name] : false;
    }
    // this.flags[name] = !this.flags[name];
    this.logger.debug('name: ', name, ' flag:', this.flags[name]);
  }

}
