<div style="display: block; position: relative; width: 100%; padding: 20px 40px;">
<p style="font-size: 24px;"><strong>Privacy Policy</strong></p>
<p>Effective December 24, 2018</p>

<p><strong>DEFINITIONS</strong></p>

<p>APPLICABLE WEBSITE: This Privacy Policy will refer to and be applicable to www.shatteredglassaudio.com website, which shall hereinafter be referred to as "Site." Any listed website may also refer to a corresponding mobile application, should one be currently in use or hereinafter developed. 
</p>
<p>EFFECTIVE DATE: "Effective Date" means the date this Privacy Policy comes into force and effect. 
</p>
<p>PARTIES: The parties to this privacy policy are the following data controller: Creative Bytes, Inc. ("Data Controller") and you, as the user of this Site. Hereinafter, the parties will individually be referred to as "Party" and collectively as "Parties." 
</p>
<p>DATA CONTROLLER: Data Controller is the publisher, owner, and operator of the Site and is the Party responsible for the collection of information described herein. Data Controller shall be referred to either by Data Controller's name or "Data Controller," as listed above. If Data Controller or Data Controller's property shall be referred to through first-person pronouns, it shall be through the use of the following: us, we, our, ours, etc. 
</p>
<p>YOU: Should you agree to this Privacy Policy and continue your use of the Site, you will be referred to herein as either you, the user, or if any second-person pronouns are required and applicable, such pronouns as 'your", "yours", etc. 
</p>
<p>GOODS: "Goods" means any goods that we make available for sale on the Site. 
</p>
<p>PERSONAL DATA: "Personal DATA" means personal data and information that we obtain from you in connection with your use of the Site which is capable of identifying you in any manner. 
</p>
 
<p><strong>GENERAL INFORMATION</strong></p>
<p>
This privacy policy (hereinafter "Privacy Policy") describes how we collect and use the Personal Data that we receive about you, as well as your rights in relation to that Personal Data, when you visit the Site or purchase our Goods.  
</p>
<p>This Privacy Policy does not cover any information that we may receive about you through sources other than the use of the Site. The Site may link out to other websites or mobile applications, but this Privacy Policy does not and will not apply to any of those linked websites or applications. 
</p>
<p>We are committed to the protection of your privacy while you use the Site. 
</p>
<p>By continuing to use the Site, you acknowledge that you have had the chance to review and consider this Privacy Policy, and you acknowledge that you agree to it. This means that you also consent to the use of your information and the method of disclosure as described in this Privacy Policy. If you do not understand the Privacy Policy or do not agree to it then you agree to immediately cease your use of the Site. 
</p>
 
<p><strong>LOCATION</strong></p>

<p>Please be advised the data processing activities take place in the United States, outside the European Economic Area. Data may also be transferred to companies within the United States, but will only be done so in a manner that complies with the EU's General Data Protection Regulation or GDPR. 
</p>
 
<p><strong>MODIFICATIONS AND REVISIONS</strong></p>

<p>We reserve the right to modify, revise, or otherwise amend this Privacy Policy at any time and in any manner. If we do so, however, we will notify you and obtain your consent to the change in processing. Unless we specifically obtain your consent, any changes to the Privacy Policy will only impact the information collected on or after the date of the change. It is also your responsibility to periodically check this page for any such modification, revision or amendment. 
</p>
 
<p><strong>PERSONAL DATA WE RECEIVE FROM YOU</strong></p>

<p>Depending on how you use the Site, you will be subject to different types of Personal Data collected and different manners of collection: </p>

<p>
a) Registered users: You, as a user of the Site, may be asked to register in order to use the Site or to purchase the Goods available for sale.  
<br>During the process of your registration, we will collect yoru email address from you through your voluntary disclosure.
<br>Personal Data may be asked for in relation to: 
<br>I) Interaction with our representatives in any way 
<br>II) making purchases 
<br>III) receiving notifications by text message or email about marketing 
<br>IV) receiving general emails from us 
<br>By undergoing the registration process, you consent to us collecting your Personal Data, including the Personal Data described in this clause, as well as storing, using or disclosing your Personal Data in accordance with this Privacy Policy. 
</p>
<p>
    b) Unregistered users: If you are a passive user of the Site and do not register for any purchases or other service, you may still be subject to certain passive data collection ("Passive Data Collection"). Such Passive Data Collection may include through cookies, as described below, IP address information, location information, and certain browser data, such as history and/or session information. 
</p>
<p>c) All users: The Passive Data Collection which applies to Unregistered users shall also apply to all other users and/or visitors of the Site. 
</p>
<p>d) Sales & Billing Information: We do not collect or hold your credit card information in relation to the Site. We use third payment processors. We receive the following information about you from the third-party payment processor: first and last name, email address, product purchased, shipping address, purchase date and time. This information is used to verify purchases and to properly distribute product license keys. 
</p>
<p>e) Related Entities: We may share your Personal Data, including Personal Data that identifies you personally, with any of our parent companies, subsidiary companies, affiliates or other trusted related entities. 
<br>However, we only share your Personal Data with a trusted related entity if that entity agrees to our privacy standards as set out in this Privacy Policy and to treat your Personal Data in the same manner that we do. 
</p>
<p>f) Email Marketing: You may be asked to provide certain Personal Data, such as your name and email address, for the purpose of receiving email marketing communications. This information will only be obtained through your voluntary disclosure and you will be asked to affirmatively opt-in to email marketing communications. 
</p>
<p>g) Combined or Aggregated Information: We may combine or aggregate some of your Personal Data in order to better serve you and to better enhance and update the Site for your and other consumers' use. 
<br>We may also share such aggregated information with others, but only if that aggregated information does not contain any Personal Data. 
</p>
 
<p><strong>THE PERSONAL DATA WE RECEIVE AUTOMATICALLY</strong></p>

<p>Cookies: We may collect information from you through automatic tracking systems (such as information about your browsing preferences) as well as through information that you volunteer to us (such as information that you provide during a registration process or at other times while using the Site, as described above). 
</p>
<p>For example, we may use cookies to make your browsing experience easier and more intuitive: cookies are small strings of text used to store some information that may concern the user, his or her preferences or the device they are using to access the Internet (such as a computer, tablet, or mobile phone). Cookies are mainly used to adapt the operation of the site to your expectations, offering a more personalized browsing experience and memorizing the choices you made previously. 
</p>
<p>A cookie consists of a reduced set of data transferred to your browser from a web server and it can only be read by the server that made the transfer. This is not executable code and does not transmit viruses. 
</p>
<p>Cookies do not record or store any Personal Data. If you want, you can prevent the use of cookies, but then you may not be able to use the Site as we intend. To proceed without changing the options related to cookies, simply continue to use the Site. 
</p>
<p>Technical cookies: Technical cookies, which can also sometimes be called HTML cookies, are used for navigation and to facilitate your access to and use of the site. They are necessary for the transmission of communications on the network or to supply services requested by you. The use of technical cookies allows the safe and efficient use of the site. 
</p>
<p>You can manage or request the general deactivation or cancelation of cookies through your browser. If you do this though, please be advised this action might slow down or prevent access to some parts of the site. 
</p>
<p>Cookies may also be retransmitted by an analytics or statistics provider to collect aggregated information on the number of users and how they visit the Site. These are also considered technical cookies when they operate as described. 
</p>
<p>Temporary session cookies are deleted automatically at the end of the browsing session - these are mostly used to identify you and ensure that you don't have to log in each time - whereas permanent cookies remain active longer than just one particular session. 
</p>
<p>Third-party cookies: We may also utilize third-party cookies, which are cookies sent by a third-party to your computer. Permanent cookies are often third-party cookies. The majority of third-party cookies consist of tracking cookies used to identify online behavior, understand interests and then customize advertising for users. 
</p>
<p>Third-party analytical cookies may also be installed. They are sent from the domains of the aforementioned third parties external to the site. Third-party analytical cookies are used to detect information on user behavior on the Site. This place anonymously, in order to monitor the performance and improve the usability of the site. Third-party profiling cookies are used to create profiles relating to users, in order to propose advertising in line with the choices expressed by the users themselves. 
</p>
<p>Support in configuring your browser: You can manage cookie through the settings of your browser on your device. However, deleting cookies from your browser may remove the preferences you have set for this Site. 
</p>
<p>For further information and support, you can also visit the specific help page of the web browser you are using.
</p>

<p>Log Data: Like all websites and mobile applications, this Site also makes use of log files which store automatic information collected during user visits. The different types of log data could be as follows: internet protocol (IP) address, type of browser and device parameters used to connect to the Site, name of the Internet Service Provider (ISP), date and time of visit, web page of origin of the user (referral) and exit, possibly the number of clicks</p>
<p>The aforementioned information is processed in an automated form and collected in an exclusively aggregated manner in order to verify the correct functioning of the site, and for security reasons. This information will be processed according to the legitimate interests of the Data Controller. 
</p>
<p>For security purposes (spam filters, firewalls, virus detection), the automatically recorded data may also possibly include Personal Data such as IP address, which could be used, in accordance with applicable laws, in order to block attempts at damage to the Site or damage to other users, or in the case of harmful activities or crime. Such data are never used for the identification or profiling of the user, but only for the protection of the Site and our users. Such information will be treated according to the legitimate interests of the Data Controller. 
    </p>
 
<p><span>THIRD PARTIES</span></p>

<p>We may utilize third party service providers ("Third Party Service Providers"), from time to time or all the time, to help us with the Site, and to help serve you. 
    </p>
<p>We may use Third Party Service Providers to assist with information storage (such as cloud storage). 
    </p>
<p>We may provide some of your Personal Data to Third Party Service Providers in order to help us track usage data, such as referral websites, dates and times of page requests, etc. We use this information to understand patterns of usage of, and to improve, the Site. 
    </p>
<p>We may use Third Party Service Providers to host the Site. In this instance, the Third Party Service Provider will have access to your Personal Data. 
    </p>
<p>We may use Third Party Service Providers to fulfill orders in relation to the Site. 
    </p>
<p>We may use Third Party Service Providers for the payment processing services in relation to the Site.
    </p>
<p>We only share your Personal Data with a Third Party Service Provider if that provider agrees to our privacy standards as set out in this Privacy Policy. 
    </p>
<p>Your Personal Data will not be sold or otherwise transferred to other third parties without your approval. 
    </p>
<p>Notwithstanding the other provisions of this Privacy Policy, we may provide your Personal Data to a third party or to third parties in order to protect the rights, property or safety, of us, our customers or third parties, or as otherwise required by law. 
    </p>
<p>We will not knowingly share your Personal Data with any third parties other than in accordance with this Privacy Policy. 
    </p>
<p>If your Personal Data might be provided to a third party in a manner which is other than as explained in this Privacy Policy, you will be notified. you will also have the opportunity to request that we not share that information. 
    </p>
 
<p><strong>WEBSITE LINKS AND SOCIAL NETWORK PLUGINS</strong></p>
<p>This Site contains links to other websites. We are not responsible for the privacy practices, content or security of any other website, which are subject to the privacy policy (if any) on such website(s). 
    </p>
<p>This Site may incorporate plugins and/or buttons for social networks, in order to allow easy sharing of content on your favorite social networks. These plugins are programmed so as not to set any cookies when accessing the page, to safeguard the privacy of users. Cookies may be set, however, if you make voluntary use of the plugin. Please note that if you browse while being logged into the social network then you have already consented to the use of cookies conveyed through this Site at the time that you registered with the particular social network. 
    </p>
<p>The collection and use of information obtained by means of the plugin are governed by the respective privacy policies of the social networks.</p>
<!-- <br>Facebook: https://www.facebook.com/help/cookies 
<br>Twitter: https://help.twitter.com/en/rules-and-policies/twitter-cookies 
<br>Google+: http: //www.google.com/policies/technologies/cookies 
<br>Pinterest: https://about.pinterest.com/it/privacy-policy 
<br>AddThis: http://www.addthis.com/privacy/privacy-policy 
<br>Linkedin: https://www.linkedin.com/legal/cookie-policy  -->

 

<p><strong>CHILDREN'S PRIVACY</strong></p> 
<p>Our Services are not designed for and are not marketed to children under the age of 18.  We do not knowingly allow such children to register as users and will not knowingly collect or solicit personal pnformation from anyone under the age of 18.  No one under the age of 18 should provide any personal information on or through this Site or otherwise. Your submission of your personal data is considered a declaration that you are 18 years or more. We delete information that we learn is collected from a minor without verified parental consent. Please contact us at support[at]shatteredglassaudio[dot]com if you believe we might have information from or about a child under the age of 18. 
</p>
 
<p><strong>HOW PERSONAL DATA IS STORED</strong></p>
<p>We use secure physical and digital systems to store your Personal Data when appropriate. We ensure that your Personal Data is protected against unauthorized access, disclosure, or destructions. 
    </p>
<p>Please note, however, that no system involving the transmission of information via the internet, or the electronic storage of data, is completely secure. However, we take the protection and storage of your Personal Data very seriously. We take all reasonable steps to protect your Personal Data. 
    </p>
<p>Personal Data is stored throughout your relationship with us. We delete your Personal Data upon request for cancelation of your account or other general request for the deletion of data. 
    </p>
<p>In the event of a breach of your Personal Data, you will be notified in a reasonable time frame, but in no event later than two weeks, and we will follow all applicable laws regarding such breach. 
    </p>
 
<p><strong>PURPOSES OF PROCESSING OF PERSONAL DATA</strong></p>
<p>We primarily use your Personal Data to help us provide a better experience for you on the Site and to provide you the services and/or information you may have requested, such as use of the Site. 
    </p>
<p>Information that does not identify you personally, but that may assist in providing us broad overviews of our customer base, will be used for market research or marketing efforts. Such information may include, but is not limited to, interests based on your cookies. 
    </p>
<p>Personal Data that may be considering identifying may be used for the following: 
<br>a) Improving your personal user experience 
<br>b) Communicating with you about your user account with us 
<br>c) Marketing and advertising to you, including via email 
<br>d) Fulfilling your purchases 
<br>e) Providing customer service to you 
</p>

 
<p><strong>DISCLOSURE OF PERSONAL DATA</strong></p>

<p>
Although our policy is to maintain the privacy of your Personal Data as described herein, we may disclose your Personal Data if we believe that it is reasonable to do so in certain cases, in our sole and exclusive discretion. Such cases may include, but are not limited to: 
<br>a) To satisfy any local, state, or Federal laws or regulations 
<br>b) To respond to requests, such discovery, criminal, civil, or administrative process, subpoenas, court orders, or writs from law enforcement or other governmental or legal bodies 
<br>c) To bring legal action against a user who has violated the law or violated the terms of use of the Site 
<br>d) As may be necessary for the operation of the Site 
<br>e) To generally cooperate with any lawful investigation about our users 
<br>f) If we suspect any fraudulent activity on the Site or if we have noticed any activity which may violate our terms or other applicable rules 
</p>
 
<p><strong>OPTING OUT OF TRANSMITTALS FROM US</strong></p>
<p>From time to time, we may send you informational or marketing communications related to the Site such as announcements or other information. If you wish to opt-out of such communications, contact: supportatshatteredglassaudiodotcom. You may also click the opt-out link which will be provided at the bottom of any and all such communications. 
    </p>
<p>Please be advised that even though you may opt-out of such communications, you may still receive information from us that is specifically about your use of the Site or about your account with us. 
    </p>
<p>By providing any Personal Data to us, or by using the Site in any manner, you have created a commercial relationship with us. As such, you agree that any email sent from us or third-party affiliates, even unsolicited email, shall specifically not be considered SPAM, as that term is legally defined. 
    </p>
 
<p><strong>MODIFYING, DELETING, AND ACCESSING YOUR INFORMATION</strong></p>
<p>If you wish to modify or delete any information we may have about you, or you wish to simply access any information we have about you, you may reach out to us : support [at] shatteredglassaudio [dot] com. 
    </p>
 
<p><strong>ACCEPTANCE OF RISK</strong></p>
<p>By continuing to the Site in any manner, use the Product, you manifest your continuing asset to this Privacy Policy. You further acknowledge, agree and accept that no transmission of information or data via the internet is not always completely secure, no matter what steps are taken. You acknowledge, agree and accept that we do not guarantee or warrant the security of any information that you provide to us, and that you transmit such information at your own risk. 
    </p>
 
<p><strong>YOUR RIGHTS</strong></p>

<p>You have many rights in relation to your Personal Data. Specifically, your rights are as follows: 
<br>Right to be informed about the processing of your Personal Data 
<br>Right to have access to your Personal Data 
<br>Right to update and/or correct your Personal Data 
<br>Right to portability of your Personal Data 
<br>Right to oppose or limit the processing of your Personal Data 
<br>Right to request that we stop processing and delete your Personal Data 
<br>Right to block any Personal Data processing in violation of any applicable law 
<br>Right to launch a complaint with the Federal Trade Commission (FTC) in the United States or applicable data protection authority in another jurisdiction 
</p>
<p>Such rights can all be exercised by contacting us at the relevant contact information listed in this Privacy Policy. 
        </p>
 
<p><strong>CONTACT INFORMATION</strong></p>
<p>If you have any questions about this Privacy Policy or the way we collect information from you, or if you would like to launch a complaint about anything related to this Privacy Policy, you may contact us at: info[at]shatteredglassaudio[dot]com. </p>
</div>