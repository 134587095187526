<div style="display: block; position: relative; width: 100%; padding: 20px 40px;">
  <p style="font-size: 24px;"><strong>Terms and Conditions</strong></p>
  <p>Effective December 24, 2018</p>

  <p><strong>THE AGREEMENT:</strong> The use of this website and services on this website provided by Creative Bytes, Inc (hereinafter referred to as &quot;Company&quot;) are subject to the following Terms and Conditions (hereinafter the &quot;Agreement&quot;), all parts and sub-parts of which are specifically incorporated by reference here. This Agreement shall govern the use of all pages on www.shatteredglassaudio.com website (hereinafter collectively referred to as &quot;Site&quot;) and any services provided by or on this Site (&quot;Services&quot;).</p>

  <p><strong>DEFINITIONS</strong></p>
<p>
  The parties referred to in this Agreement shall be defined as follows:
  a) Company, Us, We: The Company, as the creator, operator, and publisher of the Site, makes the Site, and certain Services on it, available to users. Creative Bytes, Inc, Company, Us, We, Our, Ours and other first-person pronouns will refer to the Company, as well as all employees and affiliates of the Company.
  b) You, the User, the Client: You, as the user of the Site, will be referred to throughout this Agreement with second-person pronouns such as You, Your, Yours, or as User or Client.
  c) Parties: Collectively, the parties to this Agreement (the Company and You) will be referred to as Parties.
</p>

<p><strong>ASSENT AND ACCEPTANCE</strong></p>
<p>By using the Site, You warrant that You have read and reviewed this Agreement and that You agree to be bound by it. If You do not agree to be bound by this Agreement, please leave the Site immediately. The Company only agrees to provide use of this Site and Services to You if You assent to this Agreement.</p>

<p><strong>LICENSE TO USE WEBSITE</strong></p>
<p>
  The Company may provide You with certain information as a result of Your use of the Site or Services. Such information may include, but is not limited to, documentation, data, or information developed by the Company, and other materials which may assist in Your use of the Site or Services (&quot;Company Materials&quot;). Subject to this Agreement, the Company grants You a non-exclusive, limited, non-transferable and revocable license to use the Company Materials solely in connection with Your use of the Site and Services. The Company Materials may not be used for any other purpose, and this license terminates upon Your cessation of use of the Site or Services or at the termination of this Agreement.

</p>  
<p><strong>INTELLECTUAL PROPERTY</strong></p>
<p>
  You agree that the Site and all Services provided by the Company are the property of the Company, including all copyrights, trademarks, trade secrets, patents, and other intellectual property (&quot;Company IP&quot;). You agree that the Company owns all right, title and interest in and to the Company IP and that You will not use the Company IP for any unlawful or infringing purpose. You agree not to reproduce or distribute the Company IP in any way, including electronically or via registration of any new trademarks, trade names, service marks or Uniform Resource Locators (URLs), without express written permission from the Company.
  a) In order to make the Site and Services available to You, You hereby grant the Company a royalty-free, non-exclusive, worldwide license to copy, display, use, broadcast, transmit and make derivative works of any content You publish, upload, or otherwise make available to the Site (&quot;Your Content&quot;). The Company claims no further proprietary rights in Your Content.
  b) If You feel that any of Your intellectual property rights have been infringed or otherwise violated by the posting of information or media by another of Our users, please contact Us and let Us know.

</p>   
<p><strong>USER OBLIGATIONS</strong></p>
<p>As a user of the Site or Services, You may be asked to register with Us. When You do so, You will choose a user identifier, which may be Your email address or another term, as well as a password. You may also provide personal information, including, but not limited to, Your name. You are responsible for ensuring the accuracy of this information. This identifying information will enable You to use the Site and Services. You must not share such identifying information with any third party, and if You discover that Your identifying information has been compromised, You agree to notify Us immediately in writing. Email notification will suffice. You are responsible for maintaining the safety and security of Your identifying information as well as keeping Us apprised of any changes to Your identifying information. Providing false or inaccurate information, or using the Site or Services to further fraud or unlawful activity is grounds for immediate termination of this Agreement.</p>

<p><strong>ACCEPTABLE USE</strong></p>
<p>
  You agree not to use the Site or Services for any unlawful purpose or any purpose prohibited under this clause. You agree not to use the Site or Services in any way that could damage the Site, Services, or general business of the Company.<br>
  a) You further agree not to use the Site or Services:<br>
  I) To harass, abuse, or threaten others or otherwise violate any person&#39;s legal rights;<br>
  II) To violate any intellectual property rights of the Company or any third party;<br>
  III) To upload or otherwise disseminate any computer viruses or other software that may damage the property of another;<br>
  IV) To perpetrate any fraud;<br>
  V) To engage in or create any unlawful gambling, sweepstakes, or pyramid scheme;<br>
  VI) To publish or distribute any obscene or defamatory material;<br>
  VII) To publish or distribute any material that incites violence, hate, or discrimination towards any group;<br>
  VIII) To unlawfully gather information about others.<br>
</p>

<p><strong>ASSUMPTION OF RISK</strong></p>
<p>The Site and Services are provided for communication purposes only. You acknowledge and agree that any information posted on the Site is not intended to be legal advice, medical advice, or financial advice, and no fiduciary relationship has been created between You and the Company. You further agree that Your purchase of any of the products on the Site is at Your own risk. The Company does not assume responsibility or liability for any advice or other information given on the Site.</p>

<p><strong>SALE OF GOODS/SERVICES</strong></p>
<p>The Company may sell goods or services or allow third parties to sell goods or services on the Site. The Company undertakes to be as accurate as possible with all information regarding the goods and services, including product descriptions and images. However, the Company does not guarantee the accuracy or reliability of any product information, and You acknowledge and agree that You purchase such products at Your own risk.</p> 

<p><strong>REVERSE ENGINEERING AND SECURITY</strong></p>
<p>
  You agree not to undertake any of the following actions:<br>
  a) Reverse engineer, or attempt to reverse engineer or disassemble any code or software from or on the Site or Services;<br>
  b) Violate the security of the Site or Services through any unauthorized access, circumvention of encryption or other security tools, data mining or interference to any host, user or network.
</p>

<p><strong>DATA LOSS</strong></p>
<p>The Company does not accept responsibility for the security of Your account or content. You agree that Your use of the Site or Services is at Your own risk.</p>

<p><strong>INDEMNIFICATION</strong></p>
<p>You agree to defend and indemnify the Company and any of its affiliates (if applicable) and hold Us harmless against any and all legal claims and demands, including reasonable attorney&#39;s fees, which may arise from or relate to Your use or misuse of the Site or Services, Your breach of this Agreement, or Your conduct or actions. You agree that the Company shall be able to select its own legal counsel and may participate in its own defense, if the Company wishes.</p>

<p><strong>SPAM POLICY</strong></p>
<p>You are strictly prohibited from using the Site or any of the Company&#39;s Services for illegal spam activities, including gathering email addresses and personal information from others or sending any mass commercial emails.</p>

<p><strong>THIRD-PARTY LINKS AND CONTENT</strong></p>
<p>The Company may occasionally post links to third party websites or other services. You agree that the Company is not responsible or liable for any loss or damage caused as a result of Your use of any third party services linked to from the Site.</p>

<p><strong>MODIFICATION AND VARIATION</strong></p>
<p>
  The Company may, from time to time and at any time without notice to You, modify this Agreement. You agree that the Company has the right to modify this Agreement or revise anything contained herein. You further agree that all modifications to this Agreement are in full force and effect immediately upon posting on the Site and that modifications or variations will replace any prior version of this Agreement, unless prior versions are specifically referred to or incorporated into the latest modification or variation of this Agreement.<br>
  a) To the extent any part or sub-part of this Agreement is held ineffective or invalid by any court of law, You agree that the prior, effective version of this Agreement shall be considered enforceable and valid to the fullest extent.<br>
  b) You agree to routinely monitor this Agreement and refer to the Effective Date posted at the top of this Agreement to note modifications or variations. You further agree to clear Your cache when doing so to avoid accessing a prior version of this Agreement. You agree that Your continued use of the Site after any modifications to this Agreement is a manifestation of Your continued assent to this Agreement.<br>
  c) In the event that You fail to monitor any modifications to or variations of this Agreement, You agree that such failure shall be considered an affirmative waiver of Your right to review the modified Agreement.
</p>

<p><strong>ENTIRE AGREEMENT</strong></p>
<p>This Agreement constitutes the entire understanding between the Parties with respect to any and all use of this Site. This Agreement supersedes and replaces all prior or contemporaneous agreements or understandings, written or oral, regarding the use of this Site.</p>

<p><strong>SERVICE INTERRUPTIONS</strong></p>
<p>The Company may need to interrupt Your access to the Site to perform maintenance or emergency services on a scheduled or unscheduled basis. You agree that Your access to the Site may be affected by unanticipated or unscheduled downtime, for any reason, but that the Company shall have no liability for any damage or loss caused as a result of such downtime.</p>

<p><strong>TERM, TERMINATION AND SUSPENSION</strong></p>
<p>The Company may terminate this Agreement with You at any time for any reason, with or without cause. The Company specifically reserves the right to terminate this Agreement if You violate any of the terms outlined herein, including, but not limited to, violating the intellectual property rights of the Company or a third party, failing to comply with applicable laws or other legal obligations, and/or publishing or distributing illegal material. If You have registered for an account with Us, You may also terminate this Agreement at any time by contacting Us and requesting termination. At the termination of this Agreement, any provisions that would be expected to survive termination by their nature shall remain in full force and effect.</p>

<p><strong>NO WARRANTIES</strong></p>
<p>You agree that Your use of the Site and Services is at Your sole and exclusive risk and that any Services provided by Us are on an &quot;As Is&quot; basis. The Company hereby expressly disclaims any and all express or implied warranties of any kind, including, but not limited to the implied warranty of fitness for a particular purpose and the implied warranty of merchantability. The Company makes no warranties that the Site or Services will meet Your needs or that the Site or Services will be uninterrupted, error-free, or secure. The Company also makes no warranties as to the reliability or accuracy of any information on the Site or obtained through the Services. You agree that any damage that may occur to You, through Your computer system, or as a result of loss of Your data from Your use of the Site or Services is Your sole responsibility and that the Company is not liable for any such damage or loss.</p>

<p><strong>LIMITATION ON LIABILITY</strong></p>
<p>The Company is not liable for any damages that may occur to You as a result of Your use of the Site or Services, to the fullest extent permitted by law. The maximum liability of the Company arising from or relating to this Agreement is limited to the greater of one hundred ($100) US Dollars or the amount You paid to the Company in the last six (6) months. This section applies to any and all claims by You, including, but not limited to, lost profits or revenues, consequential or punitive damages, negligence, strict liability, fraud, or torts of any kind.</p>

<p><strong>GENERAL PROVISIONS</strong></p>
<p>
  a) LANGUAGE: All communications made or notices given pursuant to this Agreement shall be in the English language.<br>
  b) JURISDICTION, VENUE AND CHOICE OF LAW: Through Your use of the Site or Services, You agree that the laws of the State of Massachusetts shall govern any matter or dispute relating to or arising out of this Agreement, as well as any dispute of any kind that may arise between You and the Company, with the exception of its conflict of law provisions. In case any litigation specifically permitted under this Agreement is initiated, the Parties agree to submit to the personal jurisdiction of the state and federal courts of the following county: Suffolk County, Massachusetts. The Parties agree that this choice of law, venue, and jurisdiction provision is not permissive, but rather mandatory in nature. You hereby waive the right to any objection of venue, including assertion of the doctrine of forum non conveniens or similar doctrine.<br>
  c) ARBITRATION: In case of a dispute between the Parties relating to or arising out of this Agreement, the Parties shall first attempt to resolve the dispute personally and in good faith. If these personal resolution attempts fail, the Parties shall then submit the dispute to binding arbitration. The arbitration shall be conducted in the following county: Suffolk County. The arbitration shall be conducted by a single arbitrator, and such arbitrator shall have no authority to add Parties, vary the provisions of this Agreement, award punitive damages, or certify a class. The arbitrator shall be bound by applicable and governing Federal law as well as the law of the following state: Massachusetts. Each Party shall pay their own costs and fees. Claims necessitating arbitration under this section include, but are not limited to: contract claims, tort claims, claims based on Federal and state law, and claims based on local laws, ordinances, statutes or regulations. Intellectual property claims by the Company will not be subject to arbitration and may, as an exception to this sub-part, be litigated. The Parties, in agreement with this sub-part of this Agreement, waive any rights they may have to a jury trial in regard to arbitral claims.<br>
  d) ASSIGNMENT: This Agreement, or the rights granted hereunder, may not be assigned, sold, leased or otherwise transferred in whole or part by You. Should this Agreement, or the rights granted hereunder, by assigned, sold, leased or otherwise transferred by the Company, the rights and liabilities of the Company will bind and inure to any assignees, administrators, successors, and executors.<br>
  e) SEVERABILITY: If any part or sub-part of this Agreement is held invalid or unenforceable by a court of law or competent arbitrator, the remaining parts and sub-parts will be enforced to the maximum extent possible. In such condition, the remainder of this Agreement shall continue in full force.<br>
  f) NO WAIVER: In the event that We fail to enforce any provision of this Agreement, this shall not constitute a waiver of any future enforcement of that provision or of any other provision. Waiver of any part or sub-part of this Agreement will not constitute a waiver of any other part or sub-part.<br>
  g) HEADINGS FOR CONVENIENCE ONLY: Headings of parts and sub-parts under this Agreement are for convenience and organization, only. Headings shall not affect the meaning of any provisions of this Agreement.<br>
  h) NO AGENCY, PARTNERSHIP OR JOINT VENTURE: No agency, partnership, or joint venture has been created between the Parties as a result of this Agreement. No Party has any authority to bind the other to third parties.<br>
  i) FORCE MAJEURE: The Company is not liable for any failure to perform due to causes beyond its reasonable control including, but not limited to, acts of God, acts of civil authorities, acts of military authorities, riots, embargoes, acts of nature and natural disasters, and other acts which may be due to unforeseen circumstances.<br>
  j) ELECTRONIC COMMUNICATIONS PERMITTED: Electronic communications are permitted to both Parties under this Agreement, including e-mail or fax. For any questions or concerns, please contact us at: info[at]shatteredglassaudio[dot]com.
</p>
</div>