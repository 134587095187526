import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ProductsComponent } from './products/products.component';
import { ProductDetailComponent } from './product-detail/product-detail.component';
import { HomeComponent } from './home/home.component';
import { StoreComponent } from './store/store.component';
import { TosComponent } from './tos/tos.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { ContactComponent } from './contact/contact.component';
import { SupportComponent } from './support/support.component';
import { ReturnComponent } from './return/return.component';
import { LicenseComponent } from './license/license.component';
import { SupportRequestComponent } from './support-request/support-request.component';
import { AboutComponent } from './about/about.component';
import { FaqComponent } from './faq/faq.component';

const routes: Routes = [
  // { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'return/:product/:txn_id', component: ReturnComponent },
  { path: 'product/:id', component: ProductDetailComponent },
  { path: 'store/:id', component: StoreComponent },
  { path: 'products', component: ProductsComponent },
  { path: 'tos', component: TosComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'support', component: SupportComponent },
  { path: 'support-request', component: SupportRequestComponent },
  { path: 'license', component: LicenseComponent },
  { path: 'about', component: AboutComponent },
  { path: 'faq', component: FaqComponent },
  { path: '', component: HomeComponent }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled' }) ],
  exports: [ RouterModule ]
})

export class AppRoutingModule { }
