<ng-container *ngIf="!isSubmitted">
  <div class="section-row" style="padding: 20px 35px 20px 35px;">
    <span class="section-title">Support Request</span>
  </div>
  <div class="section-row">
    <span class="section-text">Name *</span>
    <input style="width: 350px;"  maxlength="40" required [(ngModel)]="name" #_name="ngModel">
  </div>
  <div class="section-row">
    <span class="section-text">email *</span>
    <input style="width: 350px;"  maxlength="40" required email [(ngModel)]="email" #_email="ngModel">
  </div>
  <div class="section-row">
    <div style="display: inline-block;">
      <span class="section-text">Pick a category that best describes your problem *</span>
      <select style="width: 100%" required [(ngModel)]="problem_type" #_problem_type="ngModel">
        <option *ngFor="let item of problemTypes" [ngValue]="item">{{item}}</option>
      </select>
    </div>
  </div>
  <div class="section-row">
    <div style="display: inline-block; width:200px;">
      <span class="section-text">DAW *</span>
      <input style="width: 175px;"  maxlength="40" required [(ngModel)]="daw" #_daw="ngModel">
    </div>
    <div style="display: inline-block; width:150px; padding-left: 5px;">
      <span class="section-text">version *</span>
      <input style="width: 145px;"  maxlength="12" required [(ngModel)]="daw_ver" #_daw_ver="ngModel">
    </div>
  </div>
  <div class="section-row">
    <div style="display: inline-block; width:200px;">
      <span class="section-text">OS *</span>
      <select style="width: 175px;" required [(ngModel)]="os" #_os="ngModel">
        <option *ngFor="let item of oss" [ngValue]="item">{{item}}</option>
      </select>
    </div>
    <div style="display: inline-block; width:150px; padding-left: 5px;">
      <span class="section-text">version *</span>
      <input style="width: 145px;"  maxlength="10" required [(ngModel)]="os_ver" #_os_ver="ngModel">
    </div>
  </div>
  <div class="section-row">
    <span class="section-text">Product *</span>
    <select style="width: 350px;" required [(ngModel)]="product" #_product="ngModel">
      <option *ngFor="let prod of products" [ngValue]="prod">{{prod}}</option>
    </select>
  </div>
  <div class="section-row">
    <span class="section-text">Subject *</span>
    <input style="width: 350px;" maxlength="40" required [(ngModel)]="subject" #_subject="ngModel">
  </div>
  <div class="section-row">
    <span class="section-text">Description *</span>
    <textarea cols="40" rows="5" maxlength="400" required [(ngModel)]="description" #_description="ngModel"></textarea>
  </div>
  <re-captcha size="invisible" #captchaRef (resolved)="$event && submit($event)"></re-captcha>
  <div class="section-row" style=" width: 350px; text-align: center;">
    <button [disabled]="_name.invalid || _email.invalid || _daw.invalid || _daw_ver.invalid || _os.invalid || _os_ver.invalid || _product.invalid || _subject.invalid || _description.invalid || _problem_type.invalid"
      class="btn btn-primary sga-btn" (click)="captchaRef.execute();">Submit</button>
  </div>
</ng-container>
<div *ngIf="isSubmitted && isCompleted && !hasError" class="section-row">
  <span class="section-text">{{successMessage}}</span>
</div>
<div *ngIf="isSubmitted && isCompleted && hasError" class="section-row">
  <span class="section-text" [innerHTML]="error"></span>
</div>