import { environment } from '../environments/environment';

import { ProductInList } from './product';
import { ProductInHomeList } from './product';
// import { ProductDetail } from './product';
import { StoreItem } from './product';
// import { PHOENIX_DETAIL } from './phoenix';
// import { INFERNO_DETAIL } from './inferno';
// import { MCG_DETAIL } from './mini-cracked-glass';
// import { CODE_RED_DETAIL } from './code-red';
// import { ACE_DETAIL } from './ace';
// import { CODE_RED_FREE_DETAIL } from './code-red-free';
// import { SGA1566_DETAIL } from './sga1566';

export const PRODUCTS_LIST: ProductInList[] = [
    { id: 114, name: 'Eclipse', gearImage: 'eclipse-gear.jpg' },
    { id: 111, name: 'Kompresor', gearImage: 'kompresor-gear.jpg' },
    { id: 112, name: 'Phoenix 2', gearImage: 'phoenix-gear.jpg' },
    { id: 109, name: 'Inferno', gearImage: 'inferno-gear.jpg' },
    { id: 108, name: 'Mini Cracked Glass', gearImage: 'minicrackedglass-gear.jpg' },
    { id: 107, name: 'Code Red', gearImage: 'codered-gear.jpg' },
    { id: 103, name: 'Ace', gearImage: 'ace-gear.jpg' },
    { id: 106, name: 'Code Red Free', gearImage: 'coderedfree-gear.jpg' },
    { id: 104, name: 'SGA1566', gearImage: 'sga1566-gear.jpg' }
];

export const id2JsonName: { [id: number]: string; } = {
    103: 'ace',
    104: 'sga1566',
    106: 'coderedfree',
    107: 'codered',
    108: 'minicrackedglass',
    109: 'inferno',
    112: 'phoenix',
    114: 'eclipse',
    111: 'kompresor'
  };

export const STORE_LIST: StoreItem[] = [
    { id: 114, name: 'Eclipse', price: '$54.99', image: 'eclipse-store.jpg'},
    { id: 111, name: 'Kompresor', price: '$49.99', image: 'kompresor-store.jpg'},
    { id: 112, name: 'Phoenix 2', price: '$54.99', image: 'phoenix-store.jpg', isUpgradeable: true, upgradeFromName: 'Phoenix', upgradeFromId: '110'},
    { id: 109, name: 'Inferno', price: '$49.99', image: 'inferno-store.jpg'},
    { id: 108, name: 'Mini Cracked Glass', price: '$9.99', image: 'minicrackedglass-store.jpg'},
    { id: 107, name: 'Code Red', price: '$44.99', image: 'codered-store.jpg'},
];

export const HOME_PRODUCTS_LIST: ProductInHomeList[] = [
    { id: 114, name: 'Eclipse', image: 'eclipse-index.jpg', textColor: "#eeeeff", textShadow: "0px 0px 2px #282828", description: 'Authentic sound of push-pull circuits delivered by state-of-the-art circuit simulation.'},
    { id: 112, name: 'Phoenix 2', image: 'phoenix-index.jpg', description: 'Sonic beauty of an analog preamp delivered by state-of-the-art circuit simulation.'},
    { id: 111, name: 'Kompresor', image: 'kompresor-index.jpg', textColor: 'rgb(44, 44, 44)', description: 'Power to compress with ease.' },
    // tslint:disable-next-line:max-line-length
    { id: 109, name: 'Inferno', image: 'inferno-index.jpg',  description: 'Seven classic tube and solid state circuits in a single plug-in.' },
    { id: 107, name: 'Code Red', image: 'codered-index.jpg', textColor: 'rgb(44, 44, 44)', description: 'Sound of a famous British, all tube, console from the 60s.' }
];

// export const DETAIL_PRODUCTS_LIST: ProductDetail[] = [
//   PHOENIX_DETAIL, INFERNO_DETAIL, MCG_DETAIL, CODE_RED_DETAIL, ACE_DETAIL, CODE_RED_FREE_DETAIL, SGA1566_DETAIL
// ];
