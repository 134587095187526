<!-- <div  style="position: relative; ">
    <a routerLink="/;"><img style="display: block; height: 60px; width: auto; margin-left: auto; margin-right: auto" src="/assets/images/logo-black.png"/></a>
  </div> -->
<nav class="header-top">
  <!-- <div class="header-logo">
      <a routerLink="/"><img style="height: 53px;" src="/assets/images/logo-black.png"/></a>
  </div> -->
  <ul class="topnav" style="display: none">
    <li>
        <a class="topnav.a, header-logo" routerLink="/">
          <img src="/assets/images/logo-black.png"/>
        </a>
    </li>
    <li >
      <a class="sub active" routerLink="/products" routerLinkActive="router-link-active"> PRODUCTS </a>
      <span class="navborder"></span>
    </li>
    <li>
      <a class="sub" [routerLink]="['/faq']" routerLinkActive="router-link-active" > FAQ </a>
      <span class="navborder"></span>
    </li>
    <li>
      <a class="sub" [routerLink]="['/support']" routerLinkActive="router-link-active" > SUPPORT </a>
      <span class="navborder"></span>
    </li>
    <li>
      <a class="sub" [routerLink]="['/about']" routerLinkActive="router-link-active" > ABOUT </a>
      <span class="navborder"></span>
    </li>
  </ul>
</nav>