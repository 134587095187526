<div class="section-container">
  <span class="section-title">About Shattered Glass Audio</span>
  <span class="section-text">
    <p>
      Shattered Glass Audio was founded by musicians and engineers who were tired of having to lug heavy tube amps,
      play them at deafening volumes in order to get great sound, and having to re-tube them every so often. After
      exhaustive research and experimentation with different components the &quot;Cracked Glass&quot; guitar
      amplifier was born. Beyond our wildest expectations, Cracked Glass was an instant hit with everyone who
      played it. The rest is, as the saying goes, history.
    </p>
    <p>
      After designing several guitar amps and stompboxes we decided to take insights and knowledge of the analog gear
      and apply it to creating state-of-the-art software plug-ins. Our first two plug-ins, Ace and SGA1566, were
      instant hits. These two plug-ins and others that followed quickly became standards for other plug-ins to measure
      against.
    </p>
    <p>
      We are proud to say that today our product are praised and used by artists and producers around the world.
    </p>
  </span>
</div>