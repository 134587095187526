import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, } from '@angular/router';
import { NGXLogger } from 'ngx-logger';

import { environment } from '../../environments/environment';
import { ProductService } from '../product.service';
import { StoreItem, Product, UpgradeRequest, UpgradeResponse } from '../product';

@Component({
  selector: 'app-store',
  templateUrl: './store.component.html',
  styleUrls: ['./store.component.css']
})

export class StoreComponent implements OnInit {
  public readonly PAYPAL_URL: string;
  public product: StoreItem;
  public checkingForUpgrade: boolean = false;
  public showUpgradeForm: boolean = false;
  public upgradeHasErrors: boolean = false;
  public upgradeError: string;
  public upgradeResponse: UpgradeResponse;
  public otxn: string;
  public email: string;

  constructor(
    private route: ActivatedRoute,
    private productService: ProductService,
    private logger: NGXLogger
  ) {
    this.PAYPAL_URL = environment.paypalButtonUrl;
  }

  ngOnInit() {
    this.getProduct();
  }

  getProduct(): void {
    const id = +this.route.snapshot.paramMap.get('id');
    this.productService.getStoreItem(id)
      .subscribe(product => this.product = product);
  }

  onUpgradeHere(): void {
    this.showUpgradeForm = true;
    this.product.isUpgradeable = false;
    // this.product.price = '$foo';
  }

  verifyUpgrade(customer_email, txn_id, response) {
    this.logger.debug('custormer_email: ', customer_email);
    this.logger.debug('txn_id: ', txn_id);
    this.logger.debug('response: ', response);
    this.logger.debug('email: ', this.email);
    this.logger.debug('otxn: ', this.otxn);
    // this.showUpgradeForm = false;
    const upgradeRequest = new UpgradeRequest(response, customer_email, txn_id);
    this.showUpgradeForm = false;
    this.checkingForUpgrade = true;
    this.productService.verifyUpgrade(upgradeRequest)
      .subscribe(
        (val) => {
          this.upgradeResponse = val;
          // this.logger.debug('POST call successful value returned in body upgresp', upgradeResponse);
          this.product.price = this.upgradeResponse.price;
          this.product.buttonId = this.upgradeResponse.buttonId;
          this.upgradeHasErrors = this.upgradeResponse.hasError;
          if (this.upgradeHasErrors) {
            this.upgradeError = this.upgradeResponse.error;
            this.otxn = null;
          }
          this.checkingForUpgrade = false;
        },
        error => {
          this.logger.error('error while checking for upgrade. ');
          this.upgradeHasErrors = true;
          this.upgradeError = 'Server error has occurred while checking for upgrade. Please, try again later. If the problem persists contact Shattered Glass Audio at <a href="/support-request">www.shatteredglassaudio.com/support-request<a>.';
          this.checkingForUpgrade = false;
        }
      );
  }

  // async verifyUpgrade(customer_email, txn_id, response) {
  //   this.logger.debug('custormer_email: ', customer_email);
  //   this.logger.debug('txn_id: ', txn_id);
  //   this.logger.debug('response: ', response);
  //   this.logger.debug('email: ', this.email);
  //   this.logger.debug('otxn: ', this.otxn);
  //   // this.showUpgradeForm = false;
  //   const upgradeRequest = new UpgradeRequest(response, customer_email, txn_id);
  //   this.showUpgradeForm = false;
  //   this.checkingForUpgrade = true;
  //   await this.productService.verifyUpgrade(upgradeRequest)
  //     .then(
  //       (val) => {
  //         this.upgradeResponse = val;
  //         // this.logger.debug('POST call successful value returned in body upgresp', upgradeResponse);
  //       }
  //     )
  //     .catch(
  //       error => {
  //         this.logger.error('error while checking for upgrade. ');
  //         this.upgradeHasErrors = true;
  //         this.upgradeError = 'Server error has occurred while checking for upgrade. Please, try again later. If the problem persists contact Shattered Glass Audio at <a href="/support-request">www.shatteredglassaudio.com/support-request<a>.';
  //       }
  //     );
  //   this.checkingForUpgrade = false;
  //   // .subscribe(upgResponse => upgradeResponse = Object.assign( new UpgradeResponse(), upgResponse));
  //   // this.logger.debug('upgrade response: ', upgradeResponse);
  //   if (this.upgradeResponse) {
  //     this.product.price = this.upgradeResponse.price;
  //     this.product.buttonId = this.upgradeResponse.buttonId;
  //     this.upgradeHasErrors = this.upgradeResponse.hasError;
  //     if (this.upgradeHasErrors) {
  //       this.upgradeError = this.upgradeResponse.error;
  //     }
  //   }
  // }
}
