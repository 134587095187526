import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { NGXLogger } from 'ngx-logger';

import { ProductService } from '../product.service';
import { ProductInList } from '../product';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {
  readonly gearImagesFolderPath: string = '/assets/images';
  productsList$: Observable<ProductInList[]>;
  // productsList: ProductInList[];
  constructor(
    private productService: ProductService,
    private logger: NGXLogger
  ) { }

  ngOnInit() {
    this.productsList$ = this.productService.getProducts();
    this.logger.debug(this.productsList$);
    // this.productService.getProducts().subscribe(prodsList => this.productsList = prodsList);
  }

}
