<div class="section-container">
    <p class="section-title"><strong>Support</strong></p>
    <p class="section-text"><strong>Transaction IDs</strong><br>
      If you have lost or never got any of your transaction ID(s) we can email them to you. Please, go to the <a routerLink="/license">license page</a>. 
    </p>
    <p class="section-text"><strong>License Keys</strong><br>
      If you have lost or never got your license key you can retrieve it at the <a routerLink="/license">license page</a>.
    </p>
    <p class="section-text"><strong>Support Request</strong><br>
        If you are having trouble we are happy to help. Please, go to the <a routerLink="/support-request">support request page</a>. 
    </p>
</div>