<div class="section-container">
  <span class="section-title">FAQ</span>
  <div class="section-row">
    <h1 class="section-text subtitle" (click)="change('install');">Installing, Authorizing, and Troubleshooting</h1>
    <div class="section-row" style="padding: unset;" *ngIf="flags['install']">
      <div ngbAccordion [closeOthers]="true">
        <div ngbAccordionItem [collapsed]="true">
          <div ngbAccordionHeader>
            <button ngbAccordionButton>
              <span class="section-text" style="color: #007bff">Where do I download the full version of your products?</span>
            </button>
          </div>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <ng-template>
                <span class="section-text" >Each product web page has DOWNLOAD section. There you will find links to
                  installers.</span>
                  </ng-template>
            </div>
          </div>
        </div>
        <div ngbAccordionItem [collapsed]="true">
          <div ngbAccordionHeader>
            <button ngbAccordionButton>
              <span class="section-text"  style="color: #007bff">How do I authorize/unlock a plug-in?How do I authorize/unlock a plug-in?</span>
            </button>
          </div>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <ng-template>
                <span class="section-text">You will first need to purchase a license. After you receive your license key,
                  refer to “Product Unlocking” section in the user manual, for your plugin, for instructions on how to
                  unlock your product.</span>
              </ng-template>
            </div>
          </div>
        </div>
        <div ngbAccordionItem [collapsed]="true">
          <div ngbAccordionHeader>
            <button ngbAccordionButton>
              <span class="section-text"  style="color: #007bff">Where are your plug-ins installed?</span>
            </button>
          </div>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <ng-template>
                <span class="section-text">
                  On a Mac plug-ins are installed in the following folders:
                  <ul>
                    <li>Macintosh HD/Library/Audio/Plug-Ins/Components/</li>
                    <li>Macintosh HD/Library/Audio/Plug-Ins/VST/</li>
                    <li>Macintosh HD/Library/Audio/Plug-Ins/VST3/</li>
                    <li>Macintosh HD/Library/Application\ Support/Avid/Audio/Plug-Ins/</li>
                  </ul>
                  On a PC default locations are:
                  <ul>
                    <li>32-bit Windows:</li>
                    <ul>
                      <li>C:\Program Files\VstPlugins\</li>
                      <li>C:\Program Files\Common Files\VST3\</li>
                    </ul>
                    <li>64-bit Windows:</li>
                    <ul>
                      <li>C:\Program Files\VstPlugins\</li>
                      <li>C:\Program Files\Common Files\VST3\</li>
                      <li>C:\Program Files\Common Files\Avid\Audio\Plug-Ins\</li>
                      <li>C:\Program Files (x86)\VstPlugins\</li>
                      <li>C:\Program Files (x86)\Common Files\VST3\</li>
      
                    </ul>
                  </ul>
                </span>
              </ng-template>
            </div>
          </div>
        </div>
        <div ngbAccordionItem [collapsed]="true">
          <div ngbAccordionHeader>
            <button ngbAccordionButton>
              <span class="section-text"  style="color: #007bff">How do I uninstall your plug-in?</span>
            </button>
          </div>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <ng-template>
                <span class="section-text">
                  If you must uninstall our plug-ins:<br>
                  On a PC use Control Panel to uninstall the plug-in.<br>
                  On a Mac delete the plug-in from the following folders:
                  <ul>
                    <li>Macintosh HD/Library/Audio/Plug-Ins/Components/</li>
                    <li>Macintosh HD/Library/Audio/Plug-Ins/VST/</li>
                    <li>Macintosh HD/Library/Audio/Plug-Ins/VST3/</li>
                    <li>Macintosh HD/Library/Application\ Support/Avid/Audio/Plug-Ins/</li>
                  </ul>
                  If you must uninstall our plug-ins:<br>
                  On a PC use Control Panel to uninstall the plug-in.<br>
                  On a Mac delete the plug-in from the following folders:
                  <ul>
                    <li>Macintosh HD/Library/Audio/Plug-Ins/Components/</li>
                    <li>Macintosh HD/Library/Audio/Plug-Ins/VST/</li>
                    <li>Macintosh HD/Library/Audio/Plug-Ins/VST3/</li>
                    <li>Macintosh HD/Library/Application\ Support/Avid/Audio/Plug-Ins/</li>
                  </ul>
                </span>
              </ng-template>
            </div>
          </div>
        </div>
        <div ngbAccordionItem [collapsed]="true">
          <div ngbAccordionHeader>
            <button ngbAccordionButton>
              <span class="section-text"  style="color: #007bff">Your plug-in is not showing up in my DAW. What do I do?</span>
            </button>
          </div>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <ng-template>
                <span class="section-text">
                  The following steps usually resolve the issue:
                  <ul>
                    <li>If you are on macOS 10.13 (High Sierra) and above you may need to reboot the computer, often several
                      times, before the new plug-in is seen.</li>
                    <li>Try clearing your DAW’s plug-in cache and rescan plug-in folders. If you are using Apple Logic X see
                      Apple’s support article <a href="https://support.apple.com/en-us/HT201199">Disable, remove, or rescan
                        third-party Audio Units plug-ins</a></li>
                    <li>See if your DAW has a blacklist (eg. Cubase has a blacklist) and check if the plug-in has been
                      blacklisted.</li>
                  </ul>
                </span>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="section-row">
    <h1 class="section-text subtitle" (click)="change('purchase');">Purchasing and Upgrading</h1>
    <div class="section-row" style="padding: unset;" *ngIf="flags['purchase']">
      <div ngbAccordion [closeOthers]="true">
        <div ngbAccordionItem [collapsed]="true">
          <div ngbAccordionHeader>
            <button ngbAccordionButton>
              <span class="section-text"  style="color: #007bff">How can I buy one of your products?</span>
            </button>
          </div>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <ng-template>
                <span class="section-text">Just go to the web page for the product you want to purchse and click on the
                  button “BUY HERE”</span>
              </ng-template>
            </div>
          </div>
        </div>
        <div ngbAccordionItem [collapsed]="true">
          <div ngbAccordionHeader>
            <button ngbAccordionButton>
              <span class="section-text"  style="color: #007bff">How can I upgrade to one of your products?</span>
            </button>
          </div>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <ng-template>
                <span class="section-text">Go to the web page of the product you want to upgrade to. Click on the button “BUY
                  HERE” and then follow the instructions for upgrading.<br>
                <b>Upgrade from an upgrade is not allowed.</b></span>
              </ng-template>
            </div>
          </div>
        </div>
        <div ngbAccordionItem [collapsed]="true">
          <div ngbAccordionHeader>
            <button ngbAccordionButton>
              <span class="section-text"  style="color: #007bff">Do you have sales and discounts?</span>
            </button>
          </div>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <ng-template>
                <span class="section-text">Other than an occasional introductory sale, no.</span>
              </ng-template>
            </div>
          </div>
        </div>
        <div ngbAccordionItem [collapsed]="true">
          <div ngbAccordionHeader>
            <button ngbAccordionButton>
              <span class="section-text"  style="color: #007bff">I am a famous/struggling/up-and-coming/... producer/artist/... can I get a
                discount/plug-in-for-free?</span>
            </button>
          </div>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <ng-template>
                <span class="section-text">No. Everyone is created equal. We have free plug-ins that you are welcome to use.</span>
              </ng-template>
            </div>
          </div>
        </div>
                        
      </div>
    </div>
  </div>

  <div class="section-row">
    <h1 class="section-text subtitle" (click)="change('license');">License</h1>
    <div class="section-row" style="padding: unset;" *ngIf="flags['license']">
      <div ngbAccordion [closeOthers]="true">
        <div ngbAccordionItem [collapsed]="true">
          <div ngbAccordionHeader>
            <button ngbAccordionButton>
              <span class="section-text"  style="color: #007bff">How can I retrieve my license key?</span>
            </button>
          </div>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <ng-template>
                <span class="section-text">
              You can retrieve your license by going to SUPPORT page and clicking on <a routerLink="/license">Retrieve
                License</a><br>
              NB: It may take up to 24 hrs for us to get notified of a new purchase.
            </span>
              </ng-template>
            </div>
          </div>
        </div>
        <div ngbAccordionItem [collapsed]="true">
          <div ngbAccordionHeader>
            <button ngbAccordionButton>
              <span class="section-text"  style="color: #007bff">Can I transfer my license to someone else?</span>
            </button>
          </div>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <ng-template>
                <span class="section-text">We do not support license transfer.</span>
              </ng-template>
            </div>
          </div>
        </div>
        <div ngbAccordionItem [collapsed]="true">
          <div ngbAccordionHeader>
            <button ngbAccordionButton>
              <span class="section-text"  style="color: #007bff">I have bought a license for one of your products from one of your customers. How
                can I transfer that license to me?</span>
            </button>
          </div>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <ng-template>
                <span class="section-text">
                  We do not support license transfer. Furthermore, if you are not an authorized Shattered Glass Audio
                  reseller, reselling your license is against EULA.
                </span>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="section-row">
    <h1 class="section-text subtitle" (click)="change('demo');">Demo</h1>
    <div class="section-row" style="padding: unset;" *ngIf="flags['demo']">
      <div ngbAccordion [closeOthers]="true">
        <div ngbAccordionItem [collapsed]="true">
          <div ngbAccordionHeader>
            <button ngbAccordionButton>
              <span class="section-text"  style="color: #007bff">Where can I download demo versions?</span>
            </button>
          </div>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <ng-template>
                <span class="section-text">You will find demo download links in the DOWNLOAD section for each product.</span>
              </ng-template>
            </div>
          </div>
        </div>
        <div ngbAccordionItem [collapsed]="true">
          <div ngbAccordionHeader>
            <button ngbAccordionButton>
              <span class="section-text"  style="color: #007bff">What are the demo versions limitations?</span>
            </button>
          </div>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <ng-template>
                <span class="section-text">
                  Audio fades every 45-ish seconds for 2 seconds.<br>
                  Automation is disabled for some demos.<br>
                  Presets cannot be saved either through the DAW or via Presets Manager.<br>
                  State cannot be recalled.
                </span>
              </ng-template>
            </div>
          </div>
        </div>
        <div ngbAccordionItem [collapsed]="true">
          <div ngbAccordionHeader>
            <button ngbAccordionButton>
              <span class="section-text"  style="color: #007bff">How do I remove demo limitations?</span>
            </button>
          </div>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <ng-template>
                <span class="section-text">
                  To remove demo limitations you need to install the full version and unlock it with the license key.
                </span>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="section-row">
    <h1 class="section-text subtitle" (click)="change('analog');">Analog Gear</h1>
    <div class="section-row" style="padding: unset;" *ngIf="flags['analog']">
      <div ngbAccordion [closeOthers]="true">
        <div ngbAccordionItem [collapsed]="true">
          <div ngbAccordionHeader>
            <button ngbAccordionButton>
              <span class="section-text" style="color: #007bff">Do you still sell your analog gear and kits?</span>
            </button>
          </div>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <ng-template>
                <span class="section-text">
                  Sadly no. Some of the through hole components have been discontinued, which make is it almost impossible to reliably and cost effectively source those components.
              </span>
              </ng-template>
            </div>
          </div>
        </div>
        <div ngbAccordionItem [collapsed]="true">
          <div ngbAccordionHeader>
            <button ngbAccordionButton>
              <span class="section-text" style="color: #007bff">Do you plan on updating your analog gear gear to use readily available parts?</span>
            </button>
          </div>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <ng-template>
                <span class="section-text">Not at the moment.</span>
              </ng-template>
            </div>
          </div>
        </div>
      </div>

      <!-- <ngb-accordion [closeOthers]="true">
        <ngb-panel>
          <ng-template ngbPanelTitle>
            <span class="section-text">Do you still sell your analog gear and kits?</span>
          </ng-template>
          <ng-template ngbPanelContent>
            <span class="section-text">
                Sadly no. Some of the through hole components have been discontinued, which make is it almost impossible to reliably and cost effectively source those components.
            </span>
          </ng-template>
        </ngb-panel>
        <ngb-panel>
          <ng-template ngbPanelTitle>
            <span class="section-text">Do you plan on updating your analog gear gear to use readily available parts?</span>
          </ng-template>
          <ng-template ngbPanelContent>
            <span class="section-text">Not at the moment.</span>
          </ng-template>
        </ngb-panel>
      </ngb-accordion> -->
    </div>
  </div>
  
</div>
