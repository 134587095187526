<div class="footer-container">
  <!-- <div style="display: block; position: relative; font-size: 24px; line-height: 1.8;">CONNECT WITH US</div> -->
  <div class="connect-col" style="float: left;">
    <a class="link" [routerLink]="['/tos']" routerLinkActive="router-link-active">Terms of Service</a>
    <div class="link" style="margin: 10px 0px;">|</div>
    <a class="link" [routerLink]="['/privacy']" routerLinkActive="router-link-active">Privacy</a>
    <div class="link" style="margin: 10px 0px;">|</div>
    <a class="link" [routerLink]="['/contact']" routerLinkActive="router-link-active">Contact Us</a>
  </div>
  <div class="connect-col" style="float: right;">
    <a class="social youtube" href="https://www.youtube.com/channel/UC3ltmArncym7n7NsECWvvmQ"></a>
    <a class="social twitter" href="http://twitter.com/GlassShattering"></a>
    <a class="social facebook" href="http://www.facebook.com/pages/Shattered-Glass-Audio/118384061571629"></a>
  </div>
</div>
<div class="copyright-container">
  &#169; 2009-{{date | date:'yyyy'}} Creative Bytes, Inc.
</div>