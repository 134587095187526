import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
import { Observable, map, tap } from 'rxjs';
import { NGXLogger } from 'ngx-logger';

import { ProductDetail, DownloadProductDetailSection, ProductDetailSection } from '../product';
import { ProductService } from '../product.service';
import { environment } from 'src/environments/environment';
import { EnvironmentEnum } from 'src/environments/environment_enum';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.css']
})

export class ProductDetailComponent implements OnInit, AfterViewInit {
  private fragment: string;
  // product: ProductDetail;
  product$: Observable<ProductDetail>;

  constructor(
    private route: ActivatedRoute,
    private productService: ProductService,
    private location: Location,
    private router: Router,
    private logger: NGXLogger
  ) { 
    router.events.subscribe(s => {
      // this.logger.debug("Router event: " + s.toString())
      if (s instanceof NavigationEnd) {
        const tree = router.parseUrl(router.url);
        this.logger.debug('tree.fragment: ' + tree.fragment);
        if (tree.fragment) {
          const element = document.querySelector('#' + tree.fragment);
          if (element) { element.scrollIntoView(true); }
        }
      }
    });

  }

  ngOnInit() {
    this.getProduct();
    this.route.fragment.subscribe(fragment => { this.fragment = fragment; });
    this.logger.debug('fragment: ' + this.fragment);
    // let json = JSON.stringify( this.product, null, 2);
    // let temp: ProductDetail = JSON.parse( json);//Object.assign(new ProductDetail(), json);
    // this.logger.debug(typeof(temp));
    // this.logger.debug(temp);
    // this.logger.debug(json);
    // this.logger.debug(JSON.parse( json));
    // this.logger.debug(this.product);
  }

  getProduct(): void {
    const id = +this.route.snapshot.paramMap.get('id');
    // this.productService.getProductDetail(id)
    //   .subscribe(product => this.product = product);
    this.product$ = this.productService.getProductDetail(id).pipe(map(val => this.filterProductDetail(val)));
  }

  filterProductDetail(productDetail: ProductDetail): ProductDetail {
    for(let section of productDetail.sections){
      if(section.downloadsByEnvironment != null){
        this.logger.debug("Environment: ", environment.type);
        this.logger.debug("Downloads: ", section.downloads);
        this.processDownloadSection(section, section.downloadsByEnvironment);
        this.logger.debug("prod detail: ", productDetail);
        break;
      }
      
    }
    // productDetail.sections[2].downloads.downloadDetails
    return productDetail;
  }

  private processDownloadSection(productDetailSection: ProductDetailSection, downloadSections: DownloadProductDetailSection[]): void {
    let hasAny: boolean = false; 
    let hasCurrentEnv: boolean = false;
    productDetailSection.downloads = null;
    for(let downloadDetails of downloadSections){
      if(downloadDetails.environment != undefined && downloadDetails.environment == environment.type){
        productDetailSection.downloads = downloadDetails;
        this.logger.debug("has current env: ", environment.type, " version: ", downloadDetails.version);
        return;
      }
      if(downloadDetails.environment == undefined || downloadDetails.environment == EnvironmentEnum.ANY){
        this.logger.debug("has any env: ", environment.type, " version: ", downloadDetails.version);
        productDetailSection.downloads = downloadDetails;
        // return;
      }
    }
  }

  ngAfterViewInit(): void {
    try {
      document.querySelector('#' + this.fragment).scrollIntoView();
      this.logger.debug('fragment avi: ' + this.fragment);
    } catch (e) { }
  }

}
