<div class="section-row">
  <div class="section-container">
    <span class="section-text">How can we help you?</span>
    <select style="width: 400px;" required [(ngModel)]="selection" #_selection="ngModel">
      <option class="section-text" *ngFor="let item of ['--- Select One ---', 'Email me transaction IDs', 'Retrieve License']"
        [ngValue]="item">{{item}}</option>
    </select>
      <!-- {{_selection.value}} -->
  </div>
</div>

<div class="section-row" style="padding-top: 0px;" *ngIf="selection == 'Email me transaction IDs'">
  <div *ngIf="!emailTxnIdsResponse$" class="section-container">
    <span class="section-title">Retrieve Transaction IDs</span>
    <span class="section-text">Please enter the email address for which you want to retrieve transaction IDs.</span>
    <re-captcha size="invisible" #captchaRefTxn (resolved)="$event && sendTxnIds(txn_email.value, $event)"></re-captcha>
    <input style="width: 30%; margin-right: 5px;" required email [(ngModel)]="email" #txn_email="ngModel">
    <button [disabled]="txn_email.invalid" class="btn btn-primary sga-btn" (click)="captchaRefTxn.execute();">Submit</button>
  </div>
  <ng-container *ngIf="emailTxnIdsResponse$ | async as emailTxnIdsResponse">
    <div *ngIf="emailTxnIdsResponse.hasError" class="section-container">
      <div class="license-row">
        <span class="section-text" [innerHTML]="emailTxnIdsResponse.error"></span>
      </div>
    </div>
    <div *ngIf="emailTxnIdsRequestSubmitted && !waitingForEmailTxnIdsResponse && !emailTxnIdsResponse.hasError" class="section-container">
      <div class="license-row">
        <span class="section-text" [innerHTML]="emailTxnIdsResponse.message"></span>
      </div>
    </div>
  </ng-container>
  <div *ngIf="waitingForEmailTxnIdsResponse" class="section-container">
    <div class="license-row">
      <span class="section-text">Processing. Please, wait.</span>
    </div>
  </div>

</div>

<div class="section-row" style="padding-top: 0px;" *ngIf="selection == 'Retrieve License'">
  <div *ngIf="!licenseResponse$" class="section-container">
    <span class="section-title">Retrieve License</span>
    <span class="section-text">To retrieve the license key for your product you will need:
      <ul>
        <li>Email address you used when making the purchase.</li>
        <li>Transaction ID we emailed you after your transaction was
          complete.</li>
      </ul>
      <p>
        All fields are required.
      </p>
    </span>
    <div class="license-row">
      <div class="license-text-cell"> email:</div>
      <div class="license-input-cell">
        <input style="width: 100%;" required email [(ngModel)]="customerEmail" #customer_email="ngModel">
      </div>
    </div>
    <div class="license-row">
      <div class="license-text-cell"> Transaction ID:</div>
      <div class="license-input-cell">
        <input style="width: 100%;" required [(ngModel)]="txnId" #txn_id="ngModel">
      </div>
    </div>
    <!-- <div class="license-row">
      <div class="license-text-cell" style="width: auto;">
        <input type="checkbox" checked #display_license> Display license in the browser in case the downloaded file is
        corrupted.
      </div>
    </div> -->

    <re-captcha size="invisible" #captchaRef (resolved)="$event && getLicense(customer_email.value, txn_id.value, $event)"></re-captcha>
    <div class="license-row" style=" width: 50%; text-align: center;">
      <button [disabled]="customer_email.invalid || txn_id.invalid" class="btn btn-primary sga-btn" (click)="captchaRef.execute();">Submit</button>
    </div>
  </div>
  <ng-container *ngIf="licenseResponse$ | async as licenseResponse">
    <div *ngIf="licenseResponse.hasError" class="section-container">
      <div class="license-row">
        <span class="section-text" [innerHTML]="licenseResponse.error"></span>
      </div>
    </div>
  </ng-container>
  <div *ngIf="waitingForLicenseResponse" class="section-container">
    <div class="license-row">
      <span class="section-text">Getting your license. Please, wait.</span>
    </div>
  </div>
  <!-- <div *ngIf="licenseResponse && !licenseResponse.hasError" class="section-container">
    <div class="license-row">
      <span class="section-text">{{licenseResponse.file}}</span>
    </div>
    <div class="license-row">
      <textarea cols="80" rows="30">{{licenseResponse.license}}</textarea>
    </div>
  </div> -->
</div>