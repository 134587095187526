<div *ngIf="product">
  <div class="section-title" style="display: block; background-color: #222; color: whitesmoke;; padding-left: 50px;">{{product.name}} (Win/Mac)</div>
  <div class="store-container">
    <div class="store-inner-container">
      <div class="store-row centered" style="max-width: 830px;">
        <div class="store-col" style="overflow: visible;">
          <img src="{{'/assets/images/'+product.image}}" class="image">
        </div>
        <div class="store-col">
          <div class="price-container" [hidden]="showUpgradeForm">
            <div class="price-upg" style="font-size: 24px; font-weight: bold;">{{product.price}}</div>
            <a [href]="PAYPAL_URL+product.buttonId+(otxn?'&on0=otxn_id&os0='+otxn:'')">
              <button class="btn btn-primary sga-btn gold-btn">Buy Now</button>
            </a>
            <!-- <button class="btn btn-primary sga-btn gold-btn">Buy Now1</button> -->
          </div>
          <div *ngIf="product.isUpgradeable" class="price-container">
            <div class="price-upg">Have you purchased {{product.upgradeFromName}}? </div>
            <button (click)="onUpgradeHere()" class="btn btn-primary sga-btn">Upgrade</button>
          </div>
          <div *ngIf="checkingForUpgrade" class="price-container">
            <span class="section-text">Checking for upgrade. Please, wait.</span>
          </div>
          <div *ngIf="upgradeHasErrors" class="price-container">
            <span class="section-text" [innerHTML]="upgradeError"></span>
            <!-- <span class="section-text">{{upgradeError}}</span> -->
          </div>
          <div *ngIf="showUpgradeForm" style="display:block; position: relative">
            <p>
              To verify your upgrade eligibility we need the following items:
            </p>
            <ul>
              <li>Email address used when you purchased {{product.upgradeFromName}}.</li>
              <li>Transaction ID we emailed you after your purchase of {{product.upgradeFromName}} was complete.</li>
            </ul>
            <p>
              All fields are required.
            </p>
            <div class="verify-row">
              <div class="verify-text-cell"> email:</div>
              <div class="verify-input-cell">
                <!-- <input #customer_email (keyup)="0"> -->
                <input size="28" required email [(ngModel)]="email" #customer_email="ngModel">
              </div>
            </div>
            <div class="verify-row">
              <div class="verify-text-cell"> Transaction ID:</div>
              <div class="verify-input-cell">
                <!-- <input #txn_id (keyup)="0"> -->
                <input size="28" required [(ngModel)]="otxn" #txn_id="ngModel">
              </div>
            </div>
            <re-captcha size="invisible" #captchaRef (resolved)="$event && verifyUpgrade(customer_email.value, txn_id.value, $event)"></re-captcha>
            <div class="verify-row" style="text-align: center;">
                <button [disabled]="customer_email.invalid || txn_id.invalid" class="btn btn-primary sga-btn" (click)="captchaRef.execute();">Submit</button>
                <!-- <button [disabled]="!customer_email.value || !txn_id.value" class="btn btn-primary sga-btn" (click)="captchaRef.execute();">Submit</button> -->
                <!-- verifyUpgrade(customer_email.value, txn_id.value, response) -->
              <!-- <pre>{{ response || '[empty]' }}</pre> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="store-row">
      <p>By purchasing this license, you confirm that:</p>
      <ul>
        <li>Your system conforms to the specs in the "System Requirements" section of the product page.</li>
        <li>The product demo installs and runs without a problem on your system.</li>
        <li>You can download, install, and run the product; the product unlocking screen diplays correctly and
          is functional.</li>
      </ul>
      <p><span class="section-subtitle">All sales are final.</span></p>
      <p><span class="section-subtitle">Payment</span><br>
        We use PayPal to process payments.
        <!-- It is very important that you <strong>click on the "Return to Shattered
          Glass Audio" button, after your transaction is successfully completed</strong>. Upon return to our website
        you will be given a transaction ID that you should reference in all communications with Shattered Glass Audio
        regarding this purchase. The same transaction ID will be sent to your email address that you used when
        making the purchase. -->
      </p>
      <p><span class="section-subtitle">License Delivery</span><br>
        License key, and your transaction ID associated with the purchase, will be sent to you in a separate email after
        we get the confirmation from PayPal that your payment completed successfully. We will use the same email address you used when making the purchase to send you the license key.
      </p>
      <p><span class="section-subtitle">License Recovery</span><br>
        If you have not received your license within 24 hours of purchase, or if you loose your license, you can
        retrieve it by going to the <a [routerLink]="['/license']" routerLinkActive="router-link-active">license
          page</a>.
      </p>
    </div>
  </div>
</div>