<!-- <ngb-carousel *ngIf="images"> -->
<!-- <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" /> -->
<!-- <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/prism/1.15.0/themes/prism.css" /> -->
<!-- <div style="width: 100%; background-color: blueviolet;"> -->
<div class="sga-carousel-div" >
<ngb-carousel *ngIf="(productsList$ | async)">
  <!-- <div *ngIf="(productsList$ | async)"> -->
    <!-- <div *ngFor="let product of productsList$ | async"> -->
      <ng-template ngbSlide *ngFor="let product of productsList$ | async">
        <a [routerLink]="'/product/'+product.id"> 
          <img [src]="imagesFolderPath+'/'+product.image" class="image">
          <div class="carousel-caption left-text" [style.color]="product.textColor == undefined ? 'rgb(211, 211, 211)' : product.textColor" [style.text-shadow]="product.textShadow == undefined ? '' : product.textShadow">
            <span class="product-name">{{product.name}}</span>
            <p class="blurb">{{product.description}}</p>
          </div>
        </a>
      </ng-template>
    <!-- </div> -->
  <!-- </div> -->
  <!-- <ng-template ngbSlide>
    <img src="/assets/images/inferno-index.jpg" class="image" alt="Ace" >
    <div class="carousel-caption left-text">
      <span class="product-name">Inferno</span>
      <p class="blurb">Seven classic tube and solid state circuits in a single plug-in.</p>
    </div>
  </ng-template>
  <ng-template ngbSlide>
    <img src="/assets/images/codered-index.jpg" class="image" alt="Ace" >
    <div class="carousel-caption left-text">
      <span class="product-name">Code Red</span>
      <p class="blurb">Sound of a famous British, all tube, console from the 60s.</p>
    </div>
  </ng-template> -->
</ngb-carousel>
</div>
