import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { ProductService } from '../product.service';
import { ProductInHomeList } from '../product';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {
  readonly imagesFolderPath: string = '/assets/images';
  productsList$: Observable<ProductInHomeList[]>;
  // productsList: ProductInList[];
  constructor(
    private productService: ProductService,
    private logger: NGXLogger
  ) { }

  ngOnInit() {
    this.productsList$ = this.productService.getHomeProducts();
    this.logger.debug(this.productsList$);
    // this.productService.getProducts().subscribe(prodsList => this.productsList = prodsList);
  }

}
