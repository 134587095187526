import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';

import { environment } from '../environments/environment';

import { ProductInList, ProductDetail, StoreItem, UpgradeRequest, UpgradeResponse, LicenseRequest, LicenseResponse, SupportRequest, SupportResponse, EmailTxnIdsRequest, EmailTxnIdsResponse } from './product';
import { ProductInHomeList } from './product';
import { PRODUCTS_LIST } from './all-product';
import { HOME_PRODUCTS_LIST } from './all-product';
// import { DETAIL_PRODUCTS_LIST } from './all-product';
import { STORE_LIST } from './all-product';
import { id2JsonName } from './all-product';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})

export class ProductService {

  constructor(
    private http: HttpClient,
    private logger: NGXLogger
  ) { }

  getProducts(): Observable<ProductInList[]> {
    this.logger.debug(PRODUCTS_LIST);
    return of(PRODUCTS_LIST);
  }

  getHomeProducts(): Observable<ProductInHomeList[]> {
    this.logger.debug(HOME_PRODUCTS_LIST);
    return of(HOME_PRODUCTS_LIST);
  }

  getProductDetail(id: number): Observable<ProductDetail> {
    // @TODO add exception handling and handling of zero detail when idiots fuck around with urls 
    // const detail = DETAIL_PRODUCTS_LIST.filter((productDetail: ProductDetail) => productDetail.id === id)[0];
    // this.logger.debug('detail:', detail);
    // return of(detail);
    return this.http.get<ProductDetail>(environment.sgaServer + 'assets/json/' + id2JsonName[id] + '.json');
  }

  getStoreItem(id: number): Observable<StoreItem> {
    // @TODO add exception handling and handling of zero detail when idiots fuck around with urls 
    const item = STORE_LIST.filter((storeItem: StoreItem) => storeItem.id === id)[0];
    item.buttonId = environment.paypalButtonIds[item.name];
    this.logger.debug('item:', item);
    return of(item);
  }

  verifyUpgrade(upgradeRequest: UpgradeRequest): Observable<UpgradeResponse> {
    const url = environment.sgaServer + 'api/upgrade.php';
    this.logger.debug('URL: ', url);
    this.logger.debug('upgrade request: ', upgradeRequest);
    this.logger.debug('headers: ', httpOptions);
    return this.http.post<UpgradeResponse>(url, upgradeRequest, httpOptions);
  }

  emailTxnIds(request: EmailTxnIdsRequest): Observable<EmailTxnIdsResponse> {
    const url = environment.sgaServer + 'api/email_txn_ids.php';
    this.logger.debug('URL: ', url);
    this.logger.debug('email txn ids request: ', request);
    this.logger.debug('headers: ', httpOptions);
    return this.http.post<EmailTxnIdsResponse>(url, request, httpOptions);
  }

  getLicense(licenseRequest: LicenseRequest): Observable<LicenseResponse> {
    const url = environment.sgaServer + 'api/license.php';
    this.logger.debug('URL: ', url);
    this.logger.debug('license request: ', licenseRequest);
    this.logger.debug('headers: ', httpOptions);
    return this.http.post<LicenseResponse>(url, licenseRequest, httpOptions);
  }

  submitSupportRequest(supportRequest: SupportRequest): Observable<SupportResponse> {
    const url = environment.sgaServer + 'api/support.php';
    this.logger.debug('support request: ', supportRequest);
    return this.http.post<SupportResponse>(url, supportRequest, httpOptions);
  }

  // async verifyUpgrade(upgradeRequest: UpgradeRequest): Promise<UpgradeResponse> {
  //   const url = environment.sgaServer + 'api/upgrade.php';
  //   this.logger.debug('URL: ', url);
  //   this.logger.debug('upgrade request: ', upgradeRequest);
  //   this.logger.debug('headers: ', httpOptions);
  //   const response =  await this.http.post<UpgradeResponse>(url, upgradeRequest, httpOptions).
  //   toPromise(); // .then(response => response ).catch(this.handleError);
  //   // .pipe(
  //   //   tap((resp: UpgradeResponse) => this.logger.debug(`return=${resp.buttonId}`)),
  //   //   catchError(this.handleError<UpgradeResponse>('verifyUpgrade'))
  //   // );
  //   return response;
  // }

  // async getLicense(licenseRequest: LicenseRequest): Promise<LicenseResponse> {
  //   const url = environment.sgaServer + 'api/license.php';
  //   this.logger.debug('URL: ', url);
  //   this.logger.debug('license request: ', licenseRequest);
  //   this.logger.debug('headers: ', httpOptions);
  //   const response =  await this.http.post<LicenseResponse>(url, licenseRequest, httpOptions).
  //   toPromise();
  //   return response;
  // }

}


