import { Component, OnInit } from '@angular/core';
import { NGXLogger, INGXLoggerConfig } from 'ngx-logger';

import { saveAs } from 'file-saver';

// import { environment } from '../../environments/environment';
import { ProductService } from '../product.service';
import { LicenseRequest, LicenseResponse, EmailTxnIdsRequest, EmailTxnIdsResponse } from '../product';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Component({
  selector: 'app-license',
  templateUrl: './license.component.html',
  styleUrls: ['./license.component.css']
})

export class LicenseComponent implements OnInit {
  public selection: string = '--- Select One ---';

  public waitingForEmailTxnIdsResponse: boolean = false;
  public email: string;
  // public emailTxnIdsRequestSubmitted: boolean = false;
  // public emailTxnIdsResponse: EmailTxnIdsResponse;
  public emailTxnIdsResponse$: Observable<EmailTxnIdsResponse>;

  public txnId: string;
  public customerEmail: string;
  // public licenseResponse: LicenseResponse;
  public licenseResponse$: Observable<LicenseResponse>;
  public license: string;
  public filename: string;
  public waitingForLicenseResponse: boolean = false;
  // public licenseRequestSubmitted: boolean = false;

  public hasError: boolean = false;
  public error: string;

  constructor(
    private productService: ProductService,
    private logger: NGXLogger
  ) { }

  ngOnInit() {
  }

  sendTxnIds(txn_email: string, response: string) {
    this.logger.debug('email: ', txn_email.trim());
    this.logger.debug('response: ', response);
    const request = new EmailTxnIdsRequest(response, txn_email.trim());
    this.waitingForEmailTxnIdsResponse = true;
    this.emailTxnIdsResponse$ = this.productService.emailTxnIds(request)
      .pipe(
        map(
          (val) => {
            this.logger.debug('POST call successful value returned: ', val);
            // this.hasError = this.emailTxnIdsResponse.hasError;
            // this.error = this.emailTxnIdsResponse.error;
            this.waitingForEmailTxnIdsResponse = false;
            return val;
          }          
        ),
        catchError(error => {
            this.logger.error('Error while emailing Transaction IDs. ', error);
            // this.hasError = true;
            // this.error = 'Server error has occurred while emailing your transaction IDs. Please, try again later. If the problem persists contact Shattered Glass Audio at <a href="/support-request">www.shatteredglassaudio.com/support-request<a>.';
            let emailTxnIdsResponse = new EmailTxnIdsResponse();
            emailTxnIdsResponse.hasError = true;
            emailTxnIdsResponse.error = 'Server error has occurred while emailing your transaction IDs. Please, try again later. If the problem persists contact Shattered Glass Audio at <a href="/support-request">www.shatteredglassaudio.com/support-request<a>.';
            this.waitingForEmailTxnIdsResponse = false;
            return of(emailTxnIdsResponse);
          }
  
        )
      );
  }

  getLicense(customer_email: string, txn_id: string, response: string) {
    this.logger.debug('custormer_email: ', customer_email.trim());
    this.logger.debug('txn_id: ', txn_id.trim());
    this.logger.debug('response: ', response);
    this.logger.debug('email: ', this.email);
    this.logger.debug('txnId: ', this.txnId);
    // this.showUpgradeForm = false;
    const licenseRequest = new LicenseRequest(response, customer_email.trim(), txn_id.trim());
    this.waitingForLicenseResponse = true;
    this.licenseResponse$ = this.productService.getLicense(licenseRequest)
      .pipe(
        map((val) => {
          this.license = val.license;
          this.logger.debug('license: ', this.license);
          this.logger.debug('POST call successful value returned in body license', val);
          if (!val.hasError) {
            const blob = new Blob([this.license], { type: 'text/plain;charset=utf-8' }); // application/octet-stream' });
            saveAs(blob, val.file);
          } 
          this.waitingForLicenseResponse = false;
          return val;
        }),
        catchError(error => {
          // this.waitingForLicenseResponse = false;
          this.logger.error('Error while getting license key. ', error);
          let licenseResponse = new LicenseResponse();
          licenseResponse.hasError = true;
          licenseResponse.error = 'Server error has occurred while getting your license key. Please, try again later. If the problem persists contact Shattered Glass Audio at <a href="/support-request">www.shatteredglassaudio.com/support-request<a>.';
          // this.hasError = true;
          // this.error = 'Server error has occurred while getting your license key. Please, try again later. If the problem persists contact Shattered Glass Audio at <a href="/support-request">www.shatteredglassaudio.com/support-request<a>.';
          this.waitingForLicenseResponse = false;
          return of(licenseResponse);
        })
      );
  }

  /*
  sendTxnIds(txn_email: string, response: string) {
    this.logger.debug('email: ', txn_email.trim());
    this.logger.debug('response: ', response);
    const request = new EmailTxnIdsRequest(response, txn_email.trim());
    this.waitingForEmailTxnIdsResponse = true;
    this.emailTxnIdsRequestSubmitted = true;
    this.productService.emailTxnIds(request)
      .subscribe(
        (val) => {
          this.emailTxnIdsResponse = val;
          this.logger.debug('POST call successful value returned: ', this.emailTxnIdsResponse);
          this.hasError = this.emailTxnIdsResponse.hasError;
          this.error = this.emailTxnIdsResponse.error;
          this.waitingForEmailTxnIdsResponse = false;
        },
        error => {
          this.logger.error('Error while emailing Transaction IDs. ', error);
          // this.hasError = true;
          // this.error = 'Server error has occurred while emailing your transaction IDs. Please, try again later. If the problem persists contact Shattered Glass Audio at <a href="/support-request">www.shatteredglassaudio.com/support-request<a>.';
          this.emailTxnIdsResponse = {
            hasError: true,
            error: 'Server error has occurred while emailing your transaction IDs. Please, try again later. If the problem persists contact Shattered Glass Audio at <a href="/support-request">www.shatteredglassaudio.com/support-request<a>.'
          };
          this.waitingForEmailTxnIdsResponse = false;
        }
      );
  }

  getLicense(customer_email: string, txn_id: string, response: string) {
    this.logger.debug('custormer_email: ', customer_email.trim());
    this.logger.debug('txn_id: ', txn_id.trim());
    this.logger.debug('response: ', response);
    this.logger.debug('email: ', this.email);
    this.logger.debug('txnId: ', this.txnId);
    // this.showUpgradeForm = false;
    const licenseRequest = new LicenseRequest(response, customer_email.trim(), txn_id.trim());
    this.waitingForLicenseResponse = true;
    this.licenseRequestSubmitted = true;
    this.productService.getLicense(licenseRequest)
      .subscribe(
        (val) => {
          this.licenseResponse = val;
          this.license = this.licenseResponse.license;
          this.logger.debug('license: ', this.license);
          this.logger.debug('POST call successful value returned in body license', this.licenseResponse);
          if (!this.licenseResponse.hasError) {
            let blob = new Blob([this.license], { type: 'text/plain;charset=utf-8' }); // application/octet-stream' });
            saveAs(blob, this.licenseResponse.file);
            // const url = window.URL.createObjectURL(blob);
            // window.open(url, '_self');
          } 
          // else {
          //   this.hasError = this.licenseResponse.hasError;
          //   this.error = this.licenseResponse.error;
          // }
          this.waitingForLicenseResponse = false;
        },
        error => {
          // this.waitingForLicenseResponse = false;
          this.logger.error('Error while getting license key. ', error);
          this.licenseResponse = {
            hasError: true,
            error: 'Server error has occurred while getting your license key. Please, try again later. If the problem persists contact Shattered Glass Audio at <a href="/support-request">www.shatteredglassaudio.com/support-request<a>.',
          };
          // this.hasError = true;
          // this.error = 'Server error has occurred while getting your license key. Please, try again later. If the problem persists contact Shattered Glass Audio at <a href="/support-request">www.shatteredglassaudio.com/support-request<a>.';
          this.waitingForLicenseResponse = false;
        }
      );
  }
*/
  //   async getLicense(customer_email, txn_id, response) {
  //     this.logger.debug('custormer_email: ', customer_email);
  //     this.logger.debug('txn_id: ', txn_id);
  //     this.logger.debug('response: ', response);
  //     this.logger.debug('email: ', this.email);
  //     this.logger.debug('txnId: ', this.txnId);
  //     // this.showUpgradeForm = false;
  //     const licenseRequest = new LicenseRequest(response, customer_email, txn_id);
  //     await this.productService.getLicense(licenseRequest)
  //     .then(
  //       (val) => {
  //         this.licenseResponse = val;
  //         this.license = this.licenseResponse.license;
  //         this.logger.debug('POST call successful value returned in body license', this.licenseResponse);
  //         this.logger.debug('license: ', this.license);

  //       }
  //     )
  //     .catch(
  //       error => {
  //         this.logger.error('error while checking for upgrade. ', error);
  //         // this.upgradeHasErrors = true;
  //         // this.upgradeError = 'Server error has occurred while checking for upgrade. Please, try again later. If the problem persists contact Shattered Glass Audio at <a href="/support-request">www.shatteredglassaudio.com/support-request<a>.';
  //       }
  //     );
  // }

}
